import React, { useEffect } from "react";
import "./mobileIntrod.css";
import image from "../../css/img/1.jpg";
import image2 from "../../css/img/2.jpg";
export function MobileIntroduction() {
  return (
    <div>
      <div className="header-mobile">
        <div className="name-mobile">
          NEO<img src="https://neosama.ru/favicon.ico/" className="logo-s" alt=""/>AMA
          </div>
      </div>
      <div className="container-mobile">
        <div className="leaner-block-gradient"></div>
        <div className="name-parent-mobile">
          <div className="developers-mobile">
            <h1 className="skills-mobile">
              BACKEND
              <br />
              DEPLOYMENT
            </h1>
            <img id="myMan" src={image} alt="Моё изображение" />
            <h1 className="name-developer-mobile">Coldnead</h1>
          </div>
          <div className="developers-mobile">
            <h1 className="skills-mobile">
              DESIGN
              <br />
              FRONTEND
            </h1>
            <img id="myGirl" src={image2} alt="Моё изображение" />
            <h1 className="name-developer-mobile">Fireneart</h1>
          </div>
        </div>
        <div className="leaner-block-gradient"></div>
      </div>
      <div className="description-mobile">
        <div className="animation-mobile">
          <div className="blur-overlay-mobile-1"></div>
          <div className="text-mobile">
            <h2>
              Мы являемся профессионалами в создании
              <br />
              функционального, креативного и адаптивного
              <br />
              веб-сайта, разработанного в соответствии с
              <br />
              вашими уникальными потребностями. Наша
              <br />
              цель - превратить ваше онлайн-присутствие
              <br />в незабываемое удобство для пользоватей.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
