import React from "react";
import "./mobileComplexAnimat.css"
import image6 from "../../css/img/cat2.png";
export function MobileComplexAnimation() {
    return (
      <div className="сomplex-animation-parent-mobile">
        <div className="cat-mobile">
          <img id="Cat" src={image6} alt="Моё изображение" />
        </div>
        <div className="conteiner-name-mobileCA">
          <div className="heading-mobileCA">
            <p>сложные анимации и задачи</p>
          </div>
          <div className="blur-overlay-mobile-2"></div>
          <div className="description-skill-mobileCA">
            {" "}
            <p>
              ● Мы можем создавать впечатляющие и интерактивные движения и
              выполнять сложные задачи на веб-страницах, что делает сайты более
              привлекательными и функциональными для пользователей.
            </p>
          </div>
        </div>
      </div>
    );
}