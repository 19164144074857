import React from "react";
import "./integration.css";
import image4 from "../css/img/socnet.png";
export function Integration() {
  return (
    <div className="integration-parent">
      <div className="animation">
        <div className="blur-overlay"></div>
        <div className="skill-integration-box">
          <div className="heading">
            <p>интеграция</p>
          </div>
          <div className="description-skill">
            <p>
              ● Интеграция обеспечивает удобный доступ к авторизации, упрощающая
              распространение контента через различные социальные сети и
              предоставляет множество других выгодных возможностей.
            </p>
          </div>
        </div>
        <img id="myGirl" src={image4} alt="Моё изображение" />
      </div>
    </div>
  );
}
