import React from "react";
import './applicationAccepted.css';
import { Link } from "react-router-dom";
export function ApplicationAccepted() {
    return (
        <div className="applicationAccepted-parent">
            <Link to="/application/" target="_blank">
                <div className="arrow-back"><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 43 84">
                    <path d="M41.6356 1.36322C39.816 -0.454408 36.8666 -0.454408 35.047 1.36322L4.09341 32.2892C-1.363 37.7407 -1.36467 46.5785 4.08959 52.032L34.6981 82.6365C36.5172 84.4545 39.467 84.4545 41.2862 82.6365C43.1054 80.819 43.1054 77.8718 41.2862 76.0543L10.6698 45.4656C8.85057 43.6476 8.85057 40.7009 10.6698 38.8834L41.6356 7.94555C43.4548 6.12787 43.4548 3.1809 41.6356 1.36322Z" />
                    <path d="M41.6356 1.36322C39.816 -0.454408 36.8666 -0.454408 35.047 1.36322L4.09341 32.2892C-1.363 37.7407 -1.36467 46.5785 4.08959 52.032L34.6981 82.6365C36.5172 84.4545 39.467 84.4545 41.2862 82.6365C43.1054 80.819 43.1054 77.8718 41.2862 76.0543L10.6698 45.4656C8.85057 43.6476 8.85057 40.7009 10.6698 38.8834L41.6356 7.94555C43.4548 6.12787 43.4548 3.1809 41.6356 1.36322Z" fill="url(#paint0_linear_318_463)" />
                    <defs>
                        <linearGradient id="paint0_linear_318_463" x1="12.4996" y1="10.0002" x2="43.0003" y2="90.4998" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#CEF600" />
                            <stop offset="0.244792" stop-color="#FFB700" />
                            <stop offset="0.473958" stop-color="#FF0090" />
                            <stop offset="0.723958" stop-color="#BA00DE" />
                            <stop offset="0.958333" stop-color="#00B4C5" />
                        </linearGradient>
                    </defs>
                </svg>
                </div>
            </Link>
            <div className="border">
                <div className="background">
                    <div>
                        <div className="text1"><p>Ваша заявка принята!</p></div>
                        <div className="text2"><p>Мы постараемся ответить вам как можно скорее</p></div>
                        <div className="text3"><p>Если у вас есть какие-либо вопросы, пишите нам в telegram,
                           мы будем рады вам ответить</p></div>
                        <div className="smile"><svg  viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36 0C16.1182 0 0 16.1182 0 36C0 55.8818 16.1182 72 36 72C55.8818 72 72 55.8818 72 36C72 16.1182 55.8818 0 36 0ZM36 66C19.4318 66 6.00005 52.5682 6.00005 36C6.00005 19.4318 19.4318 6.00005 36 6.00005C52.5682 6.00005 66 19.4318 66 36C66 52.5682 52.5682 66 36 66Z" />
                            <path d="M48.0001 30C51.315 30 54.0001 27.315 54.0001 23.9999C54.0001 20.685 51.3151 18 48.0001 18C44.6851 18 42 20.685 42 24C42.0001 27.315 44.6851 30 48.0001 30Z" />
                            <path d="M24 30.0001C27.315 30.0001 30.0001 27.3151 30.0001 24C30.0001 20.6851 27.3151 18 24 18C20.685 18.0001 18 20.6851 18 24.0002C18 27.3151 20.685 30.0001 24 30.0001Z" />
                            <path d="M54 36C52.3431 36 51 37.3431 51 39C51 46.8892 43.8892 54 36 54C28.1107 54 20.9999 46.8892 20.9999 39C20.9999 37.3431 19.6568 36 18 36C16.3431 36 15 37.3431 15 39C15 50.2028 24.7972 59.9999 36 59.9999C47.2027 59.9999 56.9999 50.2027 56.9999 39C56.9999 37.3431 55.6568 36 54 36Z"/>
                        </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}