import "./css/vars.css";
import "./css/style.css";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Application } from "./general/Application";
import { MainPage } from "./general/MainPage";
import { ApplicationAccepted } from "./general/ApplicationAccepted";
import { MobilePage } from "./general/mobile/MobilePage";
import { useEffect, useState } from "react";
function App() {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  useEffect(() => {
    const resizeHandler = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [window.innerWidth]);

  return (
    <Router>
      <Routes>
        <Route path="/application/" exact element={<Application/>} />
        <Route path="/" exact element={windowSize >= 1500 ? <MainPage /> : <MobilePage />} />
        <Route path="/applicationAccepted/" exact element={<ApplicationAccepted />} />
      </Routes>
    </Router>
  );
}

export default App;