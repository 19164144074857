import React from "react";
import "./mobileAdaption.css";
import image3 from "../../css/img/iPhone13.png";
export function MobileAdaption() {
    return (
        <div className="adaptation-parent-mobile">
            <div className="conteiner-name-mobile">
                <div className="heading-mobile"><p>адаптация ко всем устройствам</p></div>
                <div className="blur-overlay-mobile-2"></div>
                <div className="description-skill-mobile"><p>
                    ● Адаптация страницы ко всем устройствам (адаптивный дизайн)
                    - это возможность создавать веб-сайты так, чтобы они хорошо
                    выглядели и работали на разных устройства, такие как
                    компьютеры, планшеты и мобильные телефоны. Это
                    улучшает пользовательский опыт и доступность сайта.</p></div>
            </div>
            <div className="mockup-mobile"><img id="IPhone13" src={image3} alt="Моё изображение" /></div>

        </div>
    )
}