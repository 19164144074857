import React, { useEffect, useRef, useState } from "react";
import "./application.css";
import { Link } from "react-router-dom";
export function Application() {
  function getCookie(name) {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : "";
  }

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [description, setDescription] = useState("");
  const send = () => {
    const formData = new FormData();
    formData.append("customer_name", customerName);
    formData.append("customer_email", customerEmail);
    formData.append("description", description);
    const csrf = getCookie("csrftoken");
    formData.append("csrfmiddlewaretoken", csrf);
    fetch("/api/create-order/", {
      method: "POST",
      body: formData,
    });
  };

  return (
    <div className="application-parent">
      <Link to="/" target="_blank">
        <div className="arrow-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 43 84"
            fill="none"
          >
            <path
              d="M41.6356 1.36322C39.816 -0.454408 36.8666 -0.454408 35.047 1.36322L4.09341 32.2892C-1.363 37.7407 -1.36467 46.5785 4.08959 52.032L34.6981 82.6365C36.5172 84.4545 39.467 84.4545 41.2862 82.6365C43.1054 80.819 43.1054 77.8718 41.2862 76.0543L10.6698 45.4656C8.85057 43.6476 8.85057 40.7009 10.6698 38.8834L41.6356 7.94555C43.4548 6.12787 43.4548 3.1809 41.6356 1.36322Z"
              fill="#0F0F0F"
            />
            <path
              d="M41.6356 1.36322C39.816 -0.454408 36.8666 -0.454408 35.047 1.36322L4.09341 32.2892C-1.363 37.7407 -1.36467 46.5785 4.08959 52.032L34.6981 82.6365C36.5172 84.4545 39.467 84.4545 41.2862 82.6365C43.1054 80.819 43.1054 77.8718 41.2862 76.0543L10.6698 45.4656C8.85057 43.6476 8.85057 40.7009 10.6698 38.8834L41.6356 7.94555C43.4548 6.12787 43.4548 3.1809 41.6356 1.36322Z"
              fill="url(#paint0_linear_318_463)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_318_463"
                x1="12.4996"
                y1="10.0002"
                x2="43.0003"
                y2="90.4998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#CEF600" />
                <stop offset="0.244792" stopColor="#FFB700" />
                <stop offset="0.473958" stopColor="#FF0090" />
                <stop offset="0.723958" stopColor="#BA00DE" />
                <stop offset="0.958333" stopColor="#00B4C5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </Link>
      <div>
        <div className="heading">
          <h2>Заказ</h2>
        </div>
        <div className="border">
          <div className="background">
            <div className="background-box">
              <div className="name-input">
                <div className="name">
                  <div>
                    <p>Ваше имя</p>
                  </div>
                  <input
                    onChange={(e) => setCustomerName(e.target.value)}
                    type="text"
                    maxLength={30}
                  />
                </div>
                <p>Почта</p>
                <input
                  type="email"
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
                <p className="description-text-mobile">Описание задачи</p>
                <p className="description-text">
                  Описание (кратко опишите задачи, цели, тип и тематику
                  сайта/страницы)
                </p>
                <textarea
                  rows={7}
                  maxLength={1000}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="information-button">
                <div className="information">
                  <div className="icon">
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 0C24.0604 0 31 6.93959 31 15.5C31 24.0604 24.0604 31 15.5 31C6.93959 31 0 24.0604 0 15.5C0 6.93959 6.93959 0 15.5 0ZM15.5 1.63158C7.84068 1.63158 1.63158 7.84068 1.63158 15.5C1.63158 23.1593 7.84068 29.3684 15.5 29.3684C23.1593 29.3684 29.3684 23.1593 29.3684 15.5C29.3684 7.84068 23.1593 1.63158 15.5 1.63158ZM15.3495 21.3536C14.0575 21.3536 13.0063 22.4047 13.0063 23.6968C13.0063 24.9888 14.0575 26.04 15.3495 26.04C16.6416 26.04 17.6927 24.9888 17.6927 23.6968C17.6927 22.4047 16.6416 21.3536 15.3495 21.3536ZM18.4622 5.71052H12.2368L13.753 19.4559H16.9461L18.4622 5.71052Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="text">
                    <p>
                      Цены будут установлены только после обсуждения с клиентом
                      и уточнения конкретных требований.
                    </p>
                  </div>
                </div>
                <Link onClick={send} to="/applicationAccepted/" target="_blank">
                  <button className="button-send">
                    <h1>Отправить запрос</h1>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
