import React from "react";
import "./mobilePrograms.css";
export function MobilePrograms() {
    const program = (name) => {
        return (
            <div className="program-name-mobile">
                <p>{name}</p>
            </div>
        );
    };
    return (
        <div className="programs-parent-mobile">
            <div className="programs-container-mobile">
                {program("Python")}
                {program("Django")}
                {program("HTML")}
                {program("Figma")}
                {program("Celery")}
                {program("Redis")}
                {program("CSS")}
                {program("Krita")}
                {program("GraphQL api")}
                {program("REST api")}
                {program("SQL")}
                {program("React")}
                {program("JQuery")}
                {program("JavaScript")}
                {program("Postman")}
                {program("Photoshop")}
            </div>
        </div>
    );
}