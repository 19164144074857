import React, { useEffect, useRef, useState } from "react";
import "./complexAnimation.css";
import image4 from "../css/img/cat1.png";
export function ComplexAnimation() {
  function eyeball(eye, event) {
    let x = eye.getBoundingClientRect().left + eye.clientWidth / 2;
    let y = eye.getBoundingClientRect().top + eye.clientHeight / 2;
    let radian = Math.atan2(event.pageX - x, event.pageY - 1400 - y); //изменить 1270 на суммарную высату всех блоков сверху
    let rot = radian * (180 / Math.PI) * -1 + 270;
    eye.style.transform = "rotate(" + rot + "deg)";
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      const eyes = document.querySelectorAll(".eye");
      eyes.forEach((eye) => eyeball(eye, event));
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="complexAnimation-parent">
      <div className="animation">
        <div className="blur-overlay"></div>
        <div className="cat">
          <div className="gg">
            <div className="eyes">
              <div className="eye-box">
                <div className="eye"></div>
              </div>
              <div className="eye"></div>
            </div>
          </div>
          <img id="Cat" src={image4} alt="Моё изображение" />
        </div>
        <div className="skill-complexAnimations-box">
          <div className="heading">
            <p>сложные анимации и задачи</p>
          </div>
          <div className="description-skill">
            <p>
              ● Мы можем создавать впечатляющие и интерактивные движения и
              выполнять сложные задачи на веб-страницах, что делает сайты более
              привлекательными и функциональными для пользователей.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
