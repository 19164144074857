import React, { useEffect } from "react";
import "./introduction.css";
import image from "../css/img/1.jpg";
import image2 from "../css/img/2.jpg";
export function Introduction() {
  useEffect(() => {
    const nameBlockColdnead = document.querySelector(".name.coldnead");
    const nameBlockFireneart = document.querySelector(".name.fireneart");

    nameBlockColdnead.addEventListener("mouseover", function () {
      nameBlockColdnead.style.backgroundColor = "rgba(192, 185, 210, 1)";
      nameBlockColdnead.querySelector("h1").textContent = "Сашенька";
    });

    nameBlockColdnead.addEventListener("mouseout", function () {
      nameBlockColdnead.style.backgroundColor = "rgba(168, 153, 209, 1)";
      nameBlockColdnead.querySelector("h1").textContent = "Coldnead";
    });

    nameBlockFireneart.addEventListener("mouseover", function () {
      nameBlockFireneart.style.backgroundColor = "rgba(192, 185, 210, 1)";
      nameBlockFireneart.querySelector("h1").textContent = "Машенька";
    });

    nameBlockFireneart.addEventListener("mouseout", function () {
      nameBlockFireneart.style.backgroundColor = "rgba(168, 153, 209, 1)";
      nameBlockFireneart.querySelector("h1").textContent = "Fireneart";
    });
  }, []);

  return (
    <div>
      <div className="header"></div>
      <div className="naming">
        <div className="leaner-block-gradient"></div>
        <div className="skill">
          <div className="skill-m">
            <div className="skill-name-m">
              <h2>
                ● BACKEND
                <br />
                <br />● DEPLOY
              </h2>
            </div>
          </div>
          <div className="skill-g">
            <div className="skill-name-g">
              <h2>
                ● FRONTEND
                <br />
                <br />● DESIGN
              </h2>
            </div>
          </div>
        </div>
        <div className="profile-parent">
          <div className="profile">
            <div className="name-parent">
              <img id="myMan" src={image} alt="Моё изображение" />
              <div className="name coldnead">
                <h1>Coldnead</h1>
              </div>
            </div>
            <div className="title">
              NEO
              <img className="logo-s" src="https://neosama.ru/favicon.ico/" alt=""/>
              AMA
            </div>
            <div className="name-parent">
              <img id="myGirl" src={image2} alt="Моё изображение" />
              <div className="name fireneart">
                <h1>Fireneart</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="leaner-block-gradient2"></div>
        <div className="description">
          <div className="animation">
            <div className="blur-overlay"></div>
            <div className="text">
              <h2>
                Мы являемся профессионалами в создании
                <br />
                функционального, креативного и адаптивного
                <br />
                веб-сайта, разработанного в соответствии с
                <br />
                вашими уникальными потребностями. Наша
                <br />
                цель - превратить ваше онлайн-присутствие
                <br />в незабываемое удобство для пользоватей.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
