import React from "react";
import "./applicationButton.css";
import { Link } from 'react-router-dom'
export function ApplicationButton() {
    return (
        <div className="applicationButton-parent">
            <Link to="/application/" target="_blank">
                <button className="applicationButton"><p>Сделать заказ</p></button>
            </Link>
        </div>
    );
}