import React, { useState } from "react";
import "./communication.css";
import image5 from "../css/img/QR-man.png";
import image6 from "../css/img/QR-woman.png";
import image8 from "../css/img/catwith.png";
import CopyToClipboard from "react-copy-to-clipboard";
export function Communication() {
    const [notification, setNotification] = useState(false);

    const handleCopy = () => {
      // Отображаем уведомление
      setNotification(true);

      // Скрываем уведомление через 1.5 секунды
      setTimeout(() => {
        setNotification(null);
      }, 1500);
    };
  return (
    <div className="communication-parent">
      <div className="communication-container">
        <div className="heading">
          <p>социальные сети для связи</p>
        </div>
        <div className="QR-code">
          <div className="QR-man">
            <img id="myGirl" src={image5} alt="Моё изображение" />
            <a href="https://web.telegram.org/a/#5319852073" target="tg">
              <button className="link-box-man">
                <div className="link">
                  <p>Перейти в чат</p>
                </div>
              </button>
            </a>
            <div className="name-man">
              <p>@Coldnead</p>
            </div>
          </div>
          <div className="donate-button-box">
            <img id="Cat" src={image8} alt="Моё изображение" />
            <CopyToClipboard text="5228 6005 4560 2387" onCopy={handleCopy}>
              <button
                className="donate"
              >
                <p>{notification ? "Скопировано" : "5228 6005 4560 2387"}</p>
              </button>
            </CopyToClipboard>

            <div className="name-operation">
              <p>Donate</p>
            </div>
          </div>
          <div className="QR-woman">
            <img id="myGirl" src={image6} alt="Моё изображение" />
            <a href="https://web.telegram.org/a/#573526027" target="tg">
              <button className="link-box-woman">
                <div className="link">
                  <p>Перейти в чат</p>
                </div>
              </button>
            </a>
            <div className="name-woman">
              <p>@Fireneart</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
