import React from "react";
import './mobileIntegration.css';
import image4 from "../../css/img/socnet.png";
export function MobileIntegration() {

    return (
        <div className="integration-parent-mobile">
            <div className="conteiner-name-integration-mobile">
                <div className="heading-integration-mobile"><p>интеграция</p></div>
                <div className="blur-overlay-mobile-2"></div>
                <div className="description-skill-integration-mobile"><p>
                    ● Интеграция обеспечивает удобный доступ к
                    авторизации, упрощающая распространение
                    контента через различные социальные сети и
                    предоставляет множество других выгодных возможностей.</p></div>
            </div>
            <div className="integration-mobile"><img id="myGirl" src={image4} alt="Моё изображение" /></div>
        </div>
    );
}