import React from "react";
import "./mobileCommunication.css";
export function MobileCommunication() {
    return (
        <div className="communication-parent-mobile">
            <div className="heading-communication-mobile"><p>социальные сети для связи</p></div>
            <div className="communication-container-mobile">
                <div className="button-box-mobile">
                    <a href="https://web.telegram.org/a/#5319852073" target="tg">
                        <button className="link-man-mobile">
                            <p>Перейти в чат</p>
                        </button>
                    </a>
                    <div className="name-man-mobile">
                        <p>@Coldnead</p>
                    </div>
                    <div className="button-box-mobile">
                        <a href="https://web.telegram.org/a/#573526027" target="tg">
                            <button className="link-woman-mobile">
                                <p>Перейти в чат</p>
                            </button>
                        </a>
                        <div className="name-woman-mobile">
                            <p>@Fireneart</p>
                        </div>
                    </div>
                    <div className="button-box-mobile">
                        <button className="donate-mobile">
                            <p>5228 6005 4560 2387</p>
                        </button>
                        <div className="name-operation-mobile">
                            <p>Donate</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}