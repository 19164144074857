import React from "react";
import "./adaptation.css"
import image3 from "../css/img/mockup-PC.png";
export function Adaptation() {
    return (
        <div className="adaptation-parent">
            <div className="animation">
                <div className="blur-overlay"></div>
                <div className="skill-adaptation-box">
                    <div className="heading"><p>адаптация</p></div>
                    <div className="description-skill"><p>
                        ● Адаптация страницы ко всем устройствам (адаптивный дизайн)

                        - это возможность создавать веб-сайты так, чтобы они хорошо

                        выглядели и работали на разных устройства, такие как

                        компьютеры, планшеты и мобильные телефоны. Это

                        улучшает пользовательский опыт и доступность сайта.</p></div>
                </div>
                <div className="mockup"><img id="IPhone13" src={image3} alt="Моё изображение" /></div>
            </div>
        </div>
    );
}