import React, { useRef } from "react";
import "./mainPage.css";
import { Adaptation } from "./Adaptation";
import { Introduction } from "./Introduction";
import { Integration } from "./Integration";
import { ApplicationButton } from "./ApplicationButton";
import { Programs } from "./Programs";
import { Communication } from "./Communication";
import { ComplexAnimation } from "./ComplexAnimations";
export function MainPage() {
  return (
    <div>
      <Introduction />
      <div className="arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267 232" fill="none">
          <path d="M34.3548 231.103C32.0877 232.68 28.9717 232.12 27.3951 229.853L1.70284 192.908C0.12624 190.641 0.686002 187.525 2.95311 185.948C5.22022 184.372 8.33616 184.932 9.91277 187.199L32.7503 220.038L65.59 197.201C67.8571 195.624 70.9731 196.184 72.5497 198.451C74.1263 200.718 73.5665 203.834 71.2994 205.411L34.3548 231.103ZM26.5789 226.114C27.951 218.476 29.4093 211.092 30.9561 203.953L40.7293 206.071C39.2092 213.086 37.7738 220.353 36.4213 227.882L26.5789 226.114ZM42.4671 160.336C47.3973 144.89 52.9331 130.88 59.1167 118.174L68.1084 122.55C62.162 134.769 56.798 148.325 51.9935 163.377L42.4671 160.336ZM83.06 79.34C92.8305 66.8422 103.69 56.2166 115.701 47.1811L121.713 55.1722C110.412 63.6735 100.178 73.6806 90.9382 85.499L83.06 79.34ZM155.432 24.4806C168.834 18.77 183.241 14.2099 198.684 10.5379L200.997 20.2667C186 23.8325 172.145 28.2292 159.352 33.6803L155.432 24.4806ZM243.135 2.56347C250.402 1.60859 257.861 0.767586 265.515 0.0221305L266.485 9.97503C258.928 10.711 251.581 11.5396 244.438 12.4782L243.135 2.56347Z" fill="white" />

        </svg>
      </div>
      <Adaptation />
      <div className="arrow2">
        <svg viewBox="0 0 754 471" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M718.892 469.177C716.863 471.05 713.699 470.923 711.826 468.894L681.302 435.83C679.428 433.801 679.555 430.638 681.584 428.765C683.613 426.891 686.776 427.018 688.649 429.047L715.782 458.437L745.173 431.304C747.202 429.431 750.365 429.557 752.238 431.586C754.111 433.615 753.985 436.779 751.956 438.652L718.892 469.177ZM10 0.500681C10 9.41438 10.4815 17.9388 11.4154 26.0952L1.48033 27.2328C0.500988 18.6796 2.46382e-05 9.77471 4.34504e-05 0.500721L10 0.500681ZM23.8908 74.9382C30.5538 90.7986 39.4679 104.916 50.3133 117.558L42.7236 124.069C31.2334 110.676 21.7558 95.6753 14.6714 78.8113L23.8908 74.9382ZM88.0724 151.144C101.55 160.492 116.463 168.777 132.554 176.199L128.366 185.28C111.846 177.66 96.4107 169.097 82.3737 159.362L88.0724 151.144ZM180.515 194.899C196.435 200.17 213.046 204.905 230.164 209.248L227.705 218.941C210.407 214.553 193.561 209.753 177.372 204.392L180.515 194.899ZM280.635 220.785C297.42 224.272 314.469 227.534 331.631 230.691L329.822 240.526C312.617 237.361 295.487 234.084 278.601 230.575L280.635 220.785ZM382.813 239.884C399.985 242.944 417.113 246.02 434.018 249.231L432.152 259.055C415.308 255.856 398.228 252.788 381.059 249.729L382.813 239.884ZM485.209 259.676C502.615 263.54 519.597 267.705 535.951 272.318L533.236 281.943C517.105 277.392 500.311 273.272 483.042 269.438L485.209 259.676ZM585.684 288.715C602.786 295.346 618.806 302.805 633.413 311.335L628.37 319.971C614.311 311.761 598.787 304.52 582.069 298.038L585.684 288.715ZM675.774 343.41C688.496 356.157 698.901 370.643 706.481 387.206L697.388 391.368C690.343 375.975 680.653 362.454 668.696 350.474L675.774 343.41ZM719.869 438.917C720.651 447.454 720.869 456.375 720.496 465.702L710.504 465.303C710.86 456.386 710.65 447.903 709.911 439.829L719.869 438.917Z" fill="white" />

        </svg>
      </div>
      <ComplexAnimation />
      <div className="arrow3">
        <svg  viewBox="0 0 488 518" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.572 516.094C31.2806 518.263 34.4243 518.637 36.5937 516.928L71.9463 489.086C74.1157 487.377 74.4894 484.234 72.7808 482.064C71.0723 479.895 67.9286 479.521 65.7592 481.23L34.3346 505.978L9.58593 474.554C7.87739 472.384 4.7337 472.011 2.56428 473.719C0.394867 475.428 0.0212427 478.571 1.72978 480.741L29.572 516.094ZM38.4652 513.59C39.5451 504.503 40.99 495.925 42.7726 487.82L33.006 485.672C31.149 494.116 29.6513 503.017 28.535 512.41L38.4652 513.59ZM60.1201 438.896C68.508 422.793 78.8389 409.259 90.7004 397.648L83.7051 390.502C71.1222 402.82 60.1469 417.198 51.2512 434.276L60.1201 438.896ZM132.334 366.868C147.161 358.447 163.153 351.307 179.916 344.806L176.3 335.482C159.253 342.094 142.782 349.434 127.395 358.173L132.334 366.868ZM229.924 327.287C246.621 321.795 263.652 316.31 280.51 310.324L277.164 300.9C260.449 306.836 243.579 312.269 226.8 317.787L229.924 327.287ZM330.315 290.265C346.592 282.668 362.389 273.98 377.295 263.638L371.595 255.422C357.25 265.374 341.967 273.791 326.086 281.203L330.315 290.265ZM418.151 227.955C429.775 215.096 440.302 200.437 449.44 183.57L440.647 178.807C431.891 194.968 421.827 208.976 410.733 221.249L418.151 227.955ZM470.16 133.574C475.066 117.587 479.021 100.141 481.908 81.06L472.02 79.5641C469.201 98.201 465.349 115.163 460.6 130.64L470.16 133.574ZM486.928 27.7128C487.307 18.931 487.5 9.86458 487.5 0.502401L477.5 0.502443C477.5 9.72728 477.31 18.6499 476.938 27.2822L486.928 27.7128Z" fill="white" />
        </svg>
      </div>
      <Integration />
      <div className="arrow4">
        <svg viewBox="0 0 435 340" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M401.035 338.036C399.083 339.989 395.917 339.989 393.964 338.036L362.145 306.216C360.192 304.264 360.192 301.098 362.145 299.145C364.097 297.193 367.263 297.193 369.216 299.145L397.5 327.43L425.784 299.145C427.737 297.193 430.903 297.193 432.855 299.145C434.808 301.098 434.808 304.264 432.855 306.216L401.035 338.036ZM10.1 0.201142C10.1 9.26852 11.6159 17.6381 14.4376 25.4399L5.03368 28.841C1.81265 19.9349 0.100057 10.4135 0.100056 0.201167L10.1 0.201142ZM45.5971 66.5741C58.1633 76.9685 73.5412 86.4053 90.8972 95.3332L86.3229 104.226C68.6046 95.1114 52.5431 85.2974 39.2233 74.2795L45.5971 66.5741ZM140.489 117.938C156.945 124.791 174.052 131.514 191.369 138.415L187.668 147.705C170.413 140.829 153.193 134.061 136.645 127.17L140.489 117.938ZM242.27 159.43C259.414 166.916 276.205 174.838 292.07 183.466L287.292 192.25C271.761 183.804 255.247 176.009 238.268 168.594L242.27 159.43ZM338.824 213.683C354.041 225.658 367.339 239.007 377.796 254.165L369.565 259.844C359.798 245.686 347.256 233.043 332.64 221.542L338.824 213.683ZM399.838 306.152C401.587 315.139 402.5 324.576 402.5 334.501L392.5 334.501C392.5 325.194 391.644 316.397 390.022 308.062L399.838 306.152Z" fill="white" />
        </svg>
      </div>
      <Programs />
      <ApplicationButton />
      <Communication />
    </div>
  );
}