import React from "react";
import "./mobileApplButton.css";
import { Link } from 'react-router-dom'
export function MobileApplicationButton() {
    return (
        <div className="applicationButton-parent-mobile">
            <Link to="/application/" target="_blank">
                <button className="applicationButton-mobile"><p>Сделать заказ</p></button>
            </Link>
        </div>
    );
}